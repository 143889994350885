import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import LogoutCookieProvider from '@peloton/auth/LogoutCookieProvider';
import { white } from '@engage/colors';
import { DarkLayout, LoadingSpinner, ErrorProvider } from '../components';
import OauthProvider from '../components/auth/OauthProvider';
import ViewProvider, { useView } from '../components/ViewProvider';
import type { ScreenHint } from '../components/ViewProvider';

const ActivationView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const router = useRouter();

  const { ViewComponent, currentView, setView } = useView();

  if (router.pathname === '/email/unverified') {
    setView('Unverified');
  }

  if (router.pathname === '/email') {
    setView('EmailVerification');
    // note: do not use code or codes as a param, it will break
  }

  if (router.pathname === `/email/on-registration`) {
    setView('EmailVerificationOnRegistration');
  }

  if (currentView === 'Loading') {
    return (
      <DarkLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingSpinner size={40} isLoading={true} fill={white} />
        </div>
      </DarkLayout>
    );
  }

  return (
    <DarkLayout removeBottomMargin={currentView === 'EmailVerificationOnRegistration'}>
      <ViewComponent />
    </DarkLayout>
  );
};

const Index: NextPage<{
  screenHint: ScreenHint | undefined;
  children: React.ReactNode;
}> = ({ screenHint }) => {
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === '/') {
      router.push('/code', undefined, { shallow: true });
    }
  }, [router.pathname, router]);

  return (
    <ErrorProvider>
      <ViewProvider screenHint={screenHint ?? 'Logging_In'}>
        <OauthProvider>
          <LogoutCookieProvider>
            <ActivationView />
          </LogoutCookieProvider>
        </OauthProvider>
      </ViewProvider>
    </ErrorProvider>
  );
};

export default Index;
